<template>
  <div class="list">
    <header-view></header-view>
    <div class="content">
      <div class="left">
        <div class="tab">
          <div class="tab-item">
            <div class="tab-title">职业：</div>
            <div class="tab-list">
              <div
                class="tabs"
                :class="{ 'tabs-active': jobIndex == 0 }"
                @click="selectJob(0)"
              >
                全部
              </div>
              <div
                class="tabs"
                :class="{ 'tabs-active': jobIndex == item.id }"
                @click="selectJob(item.id)"
                v-for="(item, index) in job"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="tab-item">
            <div class="tab-title">分类：</div>
            <div class="tab-list">
              <div
                class="tabs"
                :class="{ 'tabs-active': courseIndex == 0 }"
                @click="selectCourse(0)"
              >
                全部
              </div>
              <div
                class="tabs"
                :class="{ 'tabs-active': courseIndex == item.id }"
                @click="selectCourse(item.id)"
                v-for="(item, index) in courseTypes"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <div class="nav">
            <div class="nav-left">
              <div class="nav-item">已上线</div>
            </div>
            <div class="nav-right">
              <span>最新</span>
              /
              <span>最热</span>
            </div>
          </div>
          <div class="course">
            <div
              class="course-item"
              v-for="(item, index) in list"
              :key="index"
              @click="goDetails(item.id)"
            >
              <div class="course-img">
                <img :src="item.icon" />
              </div>
              <div class="course-t">
                <div class="txt">{{ item.title }}</div>
                <div class="fun">
                  <div class="prc">
                    <img src="../../assets/images/ps.png" alt="" />
                    {{ item.signNum }}
                  </div>
                  <div class="btn" v-if="item.isFree == 0">￥{{ item.price }}</div>
                  <div class="btn1" v-if="item.isFree == 1">免费</div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            :page-size="pageSize"
            :pager-count="pageNum"
            layout="prev, pager, next"
            :total="total"
            style="text-align: center;"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="right">
        <div class="heat">
          <div class="heat-title">最热课程</div>
          <div class="heat-list">
            <div
              class="list-item"
              v-for="(item, index) in hostList"
              :key="index"
              @click="goDetails(item.id)"
            >
              <img :src="item.icon" alt="" />
              <div class="txt">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="code">
          <div class="code-img">
            <img src="../../assets/images/gong.png" alt="" />
          </div>
          <div class="code-txt">关注公众号</div>
        </div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { courseTypes, courses, hostCourse } from "@/api/list";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      courseTypes: "",
      job: "",
      courseIndex: 0,
      jobIndex: 0,
      pageNum: 1,
      pageSize: 9,
      total: 0,
      hostList: "",
      list: "",
    };
  },
  mounted() {
    this.getTypes();
    this.getList();
    this.getHost();
  },
  methods: {
      handleCurrentChange(val){
      this.pageNum = val
      this.getList();
    },
    goDetails(id) {
      this.$router.push({
        path: `Details`,
        query: {
          id: id,
        },
      });
    },
    selectJob(index) {
      this.jobIndex = index;
      this.getList();
    },
    selectCourse(index) {
      this.courseIndex = index;
      this.getList();
    },
    getTypes() {
      courseTypes()
        .then((res) => {
          console.log(res);
          this.courseTypes = res.data.courseTypes;
          this.job = res.data.job;
        })
        .catch(() => {});
    },
    getList() {
      courses({
        courseType: this.courseIndex,
        jobType: this.jobIndex,
        sortType: 0,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
        .then((res) => {
          console.log(res);
          this.list = res.data.list;
          this.total = res.data.total
        })
        .catch(() => {});
    },
    getHost() {
      hostCourse()
        .then((res) => {
          console.log(res);
          this.hostList = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px){
  .content {
  width: 100% !important;
  }
  .right{
    display: none;
  }
  .content .left .details .course .course-item{
    margin-right: 0 !important;
  }
  .content .left .details .course{
    flex-direction: column;
    align-items: center;
  }
  .content .left .tab .tab-item .tab-list{
    flex-wrap: wrap;
  }
  .content .left .tab .tab-item .tab-list .tabs{
    flex-shrink: 0;
  }
  .content .left .tab .tab-item .tab-title{
    flex-shrink: 0;
  }
}
.list {
  background: #f9f9f9;
}
.content {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);

  .left {
    width: 867px;

    .tab {
      margin-bottom: 10px;
      padding: 30px;
      background: #fff;
      border: 1px solid #eee;

      .tab-item {
        display: flex;
        .tab-title {
          font-size: 16px;
          color: #666;
          padding: 4px 0 4px 15px;
          box-sizing: border-box;
        }

        .tab-list {
          display: flex;

          .tabs {
            margin: 0 4px 4px 0;
            padding: 4px 10px;
            color: #666;
            border-radius: 15px;
            cursor: pointer;
            transition: color 1s ease;
            transition: background 0.5s ease;

            &:hover {
              color: #fff;
              background: #08bf91;
              text-decoration: none;
            }
          }

          .tabs-active {
            color: #fff;
            background: #08bf91;
            text-decoration: none;
          }
        }
        &:last-child {
          margin-top: 10px;
        }
      }
    }
    .details {
      margin-bottom: 10px;
      padding: 30px;
      background: #fff;
      border: 1px solid #eee;
      box-sizing: border-box;
      .nav {
        display: flex;
        // justify-content: space-between;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        align-items: flex-end;

        .nav-left {
          display: flex;
          .nav-item {
            color: #4c5157;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid transparent;
            border-bottom-color: #0c9;
            font-size: 16px;
            padding-bottom: 10px;
            cursor: pointer;
          }
        }
        .nav-right {
          color: #c0c4cf;
          font-size: 12px;
          margin-left: auto;
          span {
            cursor: pointer;
          }
        }
      }
      .course {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;

        .course-item {
          width: 255px;
          background: #fff;
          box-shadow: 0 2px 2px 0 #eee;
          flex-shrink: 0;
          margin-bottom: 20px;
          margin-right: 20px;
          &:hover {
            box-shadow: 0 3px 8px 0 #eee;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          .course-img {
            width: 255px;
            height: 140px;
          }

          .course-t {
            padding: 0 20px;
            box-sizing: border-box;

            .txt {
              color: #4c5157;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 20px;
              display: flex;
            }
            .fun {
              display: flex;
              justify-content: space-between;
              padding: 20px 0;
              box-sizing: border-box;
              align-items: center;

              .prc {
                color: #9b9da2;
                font-size: 12px;
                display: flex;
                align-items: center;
                img {
                  height: 12px;
                  width: 12px;
                  margin-right: 5px;
                }
              }
              .btn {
                padding: 3px 10px;
                box-sizing: border-box;
                color: #fff;
                border-radius: 100px;
                background-color: #f66e6e;
                font-size: 12px;
              }
              .btn1 {
                padding: 3px 10px;
                box-sizing: border-box;
                color: #fff;
                border-radius: 100px;
                background-color: #ffae0f;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .right {
    width: 280px;
    .heat {
      margin-bottom: 10px;
      padding: 15px;
      background: #fff;
      border: 1px solid #eee;
      .heat-title {
        padding: 0 0 10px;
        border-bottom: 1px solid #eee;
        font-size: 16px;
        color: #4c5157;
        display: flex;
        justify-content: flex-start;
      }
      .heat-list {
        .list-item {
          padding: 10px;
          word-break: break-all;
          word-wrap: break-word;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            height: 25px;
            width: 25px;
          }
          .txt {
            color: #565a61;
            font-size: 14px;
            margin-left: 5px;
          }
        }
      }
    }
    .code {
      padding: 15px 0;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .code-img {
        height: 183px;
        width: 183px;
      }
      .code-txt {
        color: #4c5157;
        font-size: 16px;
      }
    }
  }
}
</style>
